//import jwtDecode from "jwt-decode";

// const initialState = {
//     best_selling_products: [],
//     menu: [],
//     new_products: [],
//     sections: [],
//     special_offer_products: [],
//     sliders: []
// }
export const ui = (state = {}, action) => {
    switch (action.type) {
        case "UI": {
            return {
                category_parent: action.payload.category_parent,
                best_selling_products: action.payload.best_selling_products,
                brands : action.payload.brands ,
                contact_us: action.payload.contact_us,
                menu: action.payload.menu,
                new_products: action.payload.new_products,
                sections: action.payload.sections,
                special_offer_products: action.payload.special_offer_products,
                sliders: action.payload.sliders,
                articles: action.payload.articles,
                story: action.payload.story,
                diesel_generator: action.payload.diesel_generator,
                alternator: action.payload.alternator,
                rhinestones: action.payload.rhinestones,
                silvers: action.payload.silvers,
                rhinestones_service: action.payload.rhinestones_service,
                rhinestones_rings: action.payload.rhinestones_rings,
                rhinestones_bracelets: action.payload.rhinestones_bracelets,
                silver_rings: action.payload.silver_rings,
                silver_watch: action.payload.silver_watch,
                silver_service: action.payload.silver_service,
                smart_watch : action.payload.smart_watch ,
                home_appliances : action.payload.home_appliances ,
            }
        }
        default: {
            return state
        }
    }
}
