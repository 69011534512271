import {FaInstagram} from "react-icons/all";

export const DETAILS = {
    name: "فروشگاه ونیسا",
    shortName: "فروشگاه ونیسا",
    footerName: "فروشگاه ونیسا",
    description: "",
    shortAbout:"فروشگاه ونیسا از سال 1400 فعالیت خود را در زمینه زیورآلات، بدلیجات و جواهرات مارک از جمله برندهای ژوپینگ، استیل 316 و دریم آغاز کرد. بدلیجات ژوپینگ به خاطر استفاده از آلیاژ مس به جای نیکل سبب شده که محصولاتش هیچگونه حساسیت پوستی ایجاد نکند. همچنین زیورآلات ژوپینگ به ثابت بودن رنگ شهرت یافته که دلیل اصلی آن استفاده از روکش آبکاری طلا می باشد.\n" +
        "لذا هدف ما در فروشگاه ونیسا ارائه محصولات بدلیجات در بالاترین سطح کیفیت با مناسبترین قیمت برای هموطنان عزیز است.",
    veryShortAbout: "",
    link: "https://venisastore.com",
    mobile: ["09197079419"],
    phone: [],
    email: [],
    postalCode: [],
    address: "تهران، میدان امام خمینی، خیابان ناصرخسرو، کوچه پست، قبل از بانک ایران زمین، کوچه ناهور، پلاک ۲۵",
    supportDescription: "",
    workingHours: [],
    mapIframeSrc: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d6481.397743570618!2d51.42289!3d35.684416!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzXCsDQxJzAzLjkiTiA1McKwMjUnMjIuNCJF!5e0!3m2!1sen!2s!4v1706702992970!5m2!1sen!2s",
    socialMedia: [
        /*{
            title: 'واتس اپ',
            value: '09380637888',
            name: 'whatsapp',
            link: 'https://api.whatsapp.com/send/?phone=09380637888&text&app_absent=0',
            icon: <i className="lab la-whatsapp"/>,
            isPrimary: true
        },*/
        {
            title: 'تلگرام',
            value: '09357195494',
            name: 'telegram',
            link: 'https://t.me/+989197079419',
            icon: <i className="lab la-telegram-plane"/>,
            isPrimary: true
        },
        // {
        //     title: 'اینستاگرام',
        //     value: 'ayegh_komijani',
        //     name: 'instagram',
        //     link: "https://instagram.com/ayegh_komijani",
        //     icon: <FaInstagram/>,
        //     isPrimary: true
        // },
        /*{
                    title: 'ایتا',
                    value: '09357195494',
                    name: 'eitaa',
                    link: 'https://eitaa.com/09357195494',
                    icon: <img src={eitaaIcon} alt='ایتا'/>,
                    isPrimary: false
                }
        {
            title: 'ایمیل',
            value: 'Volvotak@gmail.com',
            name: 'email',
            link: 'mailto:Volvotak@gmail.com',
            icon: <i className="las la-envelope"></i>,
            isPrimary: true
        },
        {
            title: 'تس',
            value: '09133377432',
            name: 'phone',
            link: 'tel:989133377432',
            icon: <PhoneCall/>,
            isPrimary: true
        },
                /*{
                    title: 'آپارات',
                    value: 'Alo_aghsat',
                    name: 'aparat',
                    link: 'https://www.aparat.com/Alo_aghsat',
                    icon: <SiAparat size={25}/>,
                    isPrimary: true
                },
                {
                    title: 'تماس',
                    value: '02636634893',
                    name: 'phone',
                    link: 'tel:+982636634893',
                    icon: <PhoneCall/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09331074475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09332094475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09330324475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'ایتا',
                    value: '09331074475',
                    name: 'eitaa',
                    link: '',
                    icon: <img src={eitaaIcon} alt='ایتا'/>,
                    isPrimary: false
                },*/
    ],
    services: [],
    brands: [
        /*{
            name: 'Apple',
            image: <img src={appleImage} alt='apple'/>,
        },*/
    ],
    enamad: {
        link: 'https://trustseal.enamad.ir/?id=466410&Code=XFDi80tk2nXXGi7mvk3SjGzYMobAUzHH',
        src:'https://trustseal.enamad.ir/logo.aspx?id=466410&Code=XFDi80tk2nXXGi7mvk3SjGzYMobAUzHH',
        code: 'XFDi80tk2nXXGi7mvk3SjGzYMobAUzHH'
    },
    "samandehiLink": "",
    "ZarinpalLink": "https://www.zarinpal.com/webservice/TrustCode"
}
